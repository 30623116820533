import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Parametro } from "../model/app.parametro.model";

@Injectable()
export class RotasConsulta {

	readonly _baseUrlConsulta: string = `${environment.apiConsultaUrl}v1/`;

	get ObterIndustriasAgrotoxico(): string { return `${this._baseUrlConsulta}Industria`; }
	get ObterTiposAtividadesInstituicao(): string { return `${this._baseUrlConsulta}Instituicao/ObterTiposAtividadesInstituicao`; }
	get ObterInstituicaoConsultaPublica(): string { return `${this._baseUrlConsulta}Instituicao/ConsultaPublica`; }
	get ObterInstituicaoPorInscricao(): string { return `${this._baseUrlConsulta}Instituicao/InstituicaoDadosComplementaresPorInscricao`; }
	get ObterInstituicaoPorInscricaoResumida(): string { return `${this._baseUrlConsulta}Instituicao`; }
	get ObterOrgaosEmissores(): string { return `${this._baseUrlConsulta}OrgaoEmissor/ObterOrgaosEmissores`; }
	get ObterCentraisRecebimentoEmbalagens(): string { return `${this._baseUrlConsulta}CentralPostoRecebimento/ObterCentraisRecebimentoEmbalagens`; }
	get ObterTiposLicencaAmbiental(): string { return `${this._baseUrlConsulta}TipoLicencaAmbiental/ObterTiposLicencaAmbiental`; }

	get ObterTiposVacina(): string { return `${this._baseUrlConsulta}TipoVacina`; }
	get ObterTipoVacinaEspecificado(): string { return `${this._baseUrlConsulta}TipoVacina/{0}/TipoVacinaEspecificado`; }
	get ObterInstituicaoRevendaVacina(): string { return `${this._baseUrlConsulta}Instituicao/RevendaVacina`; }

	get ObterRelatorioSemestralVistoriaMonitoramento(): string { return `${this._baseUrlConsulta}Plantio/ObterRelatorioSemestralVistoriaMonitoramento`; }
	get ObterUnidadeProducao(): string { return `${this._baseUrlConsulta}Plantio/ObterUnidadeProducao`; }
	get ObterMesesPorSemestre(): string { return `${this._baseUrlConsulta}Plantio/ObterMesesPorSemestre`; }
	get ObterSemestres(): string { return `${this._baseUrlConsulta}Plantio/ObterSemestres` };


	adicionarParametros(urlBase: string, parametros: Parametro[]) {
		let parametrosUrl: string = urlBase + '?';

		for (let i = 0; i < parametros.length; i++) {
			parametrosUrl += parametros[i].nome + '=' + parametros[i].data;
			if (i !== parametros.length - 1) {
				parametrosUrl += '&';
			}
		}
		return parametrosUrl;
	}

	parseRota(urlBase: string, valores: any[]) {
		let rota = urlBase;
		for (let i = 0; i < valores.length; i++) {
			const local = '{' + i + '}';
			rota = rota.replace(local, valores[i]);
		}
		return rota;
	}
}
